<template>
  <div class="page">
    <div class="page__header row justify-content-center">
      <div class="col-12 col-xs-11 page__header__content">
        <h1 class="page__header__title">{{ $t('navSideInvoices') }}</h1>
        <!--
        <form id="search"><search-input-gcr name="query" v-model="searchQuery" :placeholder="$t('projectSearch')" /></form>
        -->
      </div>
    </div>
    <div class="page__body row justify-content-center">
      <div class="col-12 col-xs-11 page__body__col"><invoice-list></invoice-list></div>
    </div>
  </div>
</template>

<script>
import InvoiceList from '@/components/organism/InvoiceList'

require('../utilities/page')
require('../utilities/form')

export default {
  components: { InvoiceList }
}
</script>

<style lang="scss" scoped>
@import '../utilities/config';
.page__header__title {
  &::after {
    background-image: url(../assets/invoice_gray.svg);
  }
}
</style>
