<template>
  <div>
    <loading-wrapper :isLoading="gridLoading">
      <grid-gcr :data="gridData" :columns="gridColumns"></grid-gcr>
      <paging-gcr :callback="changePage" :pageCount="pageCount"></paging-gcr>
    </loading-wrapper>
  </div>
</template>

<script>
import { Consts, Cash, Dates } from '@/_helpers'
import GridGcr from '@/components/molecules/Grid'
import PagingGcr from '@/components/molecules/Paging'
import LoadingWrapper from '@/components/organism/LoadingWrapper'
import getInvoices from '@/graphql/accreditations/getInvoices.graphql'

require('../../utilities/button')

export default {
  components: { GridGcr, PagingGcr, LoadingWrapper },
  data() {
    return {
      gridLoading: false,
      gridColumns: [
        { sortable: false, name: 'invoiceNumber', class: 'valign-center' },
        { sortable: false, name: 'invoiceAmount', class: 'valign-center' },
        { sortable: false, name: 'invoiceIssueDate', class: 'valign-center' },
        { sortable: false, name: 'paymentCodeColumn', class: 'valign-center' },
        { sortable: false, name: 'invoiceStatus', class: 'valign-center' },
        { sortable: false, name: 'invoiceDownload', class: 'valign-center' }
      ],
      gridData: [],
      pageIndex: 1,
      pageCount: 0
    }
  },
  computed: {},
  apollo: {
    getinvoices: {
      query: getInvoices,
      variables() {
        return {
          id: this.$store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID],
          page: this.pageIndex
        }
      },
      update(data) {
        this.pageCount = data.accreditation.company.invoices.totalPages
        this.gridData = data.accreditation.company.invoices.items.map(inv => ({
          id: inv.id,
          onclick: `${Consts.ROOT_API}${inv.downloadUrl}`,
          invoiceNumber: inv.number,
          invoiceAmount: `<strong>${Cash.format(inv.grandTotal)}</strong>`,
          invoiceIssueDate: Dates.formatDate(inv.issueDate),
          paymentCodeColumn: inv.paymentCode,
          invoiceStatus: {
            type: 'list',
            list: [
              {
                badge: inv.balance > 0 || inv.cancelled ? 'badge-exclamation' : 'badge-check',
                badgeText: '',
                text: inv.cancelled
                  ? this.$t('canceledFemale')
                  : inv.balance > 0
                  ? this.$t('unpaidFemale')
                  : this.$t('paidFemale')
              }
            ]
          },
          invoiceDownload: {
            type: 'list',
            list: [
              {
                button: 'btn btn-download',
                buttonTooltip: this.$t('download'),
                onclick: () => this.download(inv.downloadUrl)
              }
            ]
          }
        }))
        this.$emit('load')
      },
      watchLoading(isLoading) {
        this.gridLoading = isLoading
      }
    }
  },
  methods: {
    changePage(index) {
      this.pageIndex = index
    },
    download(downloadUrl) {
      window.locationHref(`${Consts.ROOT_API}${downloadUrl}`)
    }
  }
}

window.locationHref = function(url) {
  window.location.href = url
}
</script>
